<section *ngIf="show">
  <div
    class="align-items-center bg-white border-bottom d-flex justify-content-between position-sticky px-4 py-2 top-0 z-index-9">
    <h3 class="mb-0">History</h3>
    <div class="d-flex align-items-center">
      <select (change)="changeOrder()" class="history-order-select mr-4">
        <option>Newest to oldest</option>
        <option>Oldest to newest</option>
      </select>
      <div (click)="historyClose()" class="cursor-pointer">
        <i class="icon-close"></i>
      </div>
    </div>
  </div>

  <div class="container" *ngIf="scanLogs">
    <button mat-flat-button class="btn-main" (click)="openScanLogs()">View Scan Logs</button>
  </div>

  <div class="timeline-wrapper">
    <div class="timeline">
      <div class="container" *ngFor="let item of historyArray">
        <label class="mb-4">{{ item.key }}</label>
        <div class="content" *ngFor="let content of item.value">
          <!-- <p>14:00 - Truck Request [Truck Request # <a href="#">546238</a>]</p> -->
          <!-- <p>{{ content.updated_at | date: 'h:mm' }} {{ (content.event === 'status') ? (content.new_values.status |
            titlecase) : (content.event | titlecase)}} [ {{ insertSpaces(content.auditable_type) | slice: 11 }} <a># {{
              content.tags }}</a> ]</p>
          <h5>{{ content.user_type }}</h5> -->
          <div class="d-flex align-items-start justify-content-between">
            <div>
              <p class="fs-6">{{ content.updated_at | date :'fullTime' }} <br> {{ insertSpaces(content.auditable_type) |
                slice: 11 }} <a># {{ content.tags }}</a></p>
              <h5> {{ content.user_type }}</h5>
            </div>
            <button
              [attr.history-status]="(content.event === 'status') ?(content.new_values.status | lowercase) : (content.event | lowercase)"
              mat-stroked-button style="transform: scale(0.78);">
              {{ (content.event === 'status')
              ?(content.new_values.status | titlecase) : (content.event | titlecase)}}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- footer button -->
  <footer id="history-footer" class="bottom-0 position-sticky bg-white border-top z-index-9">
    <button mat-flat-button class="btn-main" (click)="historyClose()">Close</button>
  </footer>

  <div class="history-overlay" (click)="historyClose()"></div>
</section>