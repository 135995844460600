import { KeyValue } from '@angular/common';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { audit, map, Subscription } from 'rxjs';
import { HistoryService } from './history.service';
import { NotesComponent } from '../notes/notes.component';
import { MatDialog } from '@angular/material/dialog';
import { ScanLogsComponent } from '../scan-logs/scan-logs.component';
import { NavigationEnd, Router } from '@angular/router';

interface HistroyObject {
  [key: string]: {
    id: number,
    user_type: string,
    new_values: { status: string, po_no: number },
    updated_at: string,
    created_at: string,
    event: string,
    auditable_type: string,
    tags: string,
  }[];
}

@Component({
  selector: 'app-history',
  templateUrl: './history.component.html',
  styleUrls: ['./history.component.scss']
})
export class HistoryComponent implements OnInit, OnDestroy {
  show: boolean = false;
  historyArray: any;
  scanLogs: any;

  historySubscription: Subscription;
  currentUrl: string;

  historyClose() {
    this.historyService.close();
  }

  constructor(private historyService: HistoryService, public dialog: MatDialog, private router: Router) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        if (event.url !== this.currentUrl) {
          this.historyClose();
        }
        this.currentUrl = event.url;
      }
    });
  }

  ngOnDestroy(): void {
    //  throw new Error('Method not implemented.');
  }

  ngOnInit(): void {
    this.historySubscription = this.historyService.historyState
      .subscribe({
        next: (state) => {
          this.show = state.show;
          this.historyArray = []; //temporary
          // this.historyArray = Object.keys(state.audits.date).map(key=> ({ value:state.audits.date[key], key }) );
          // if (state.audits.scan_logs) {
          //   this.scanLogs = state.audits.scan_logs;
          // }
        }
      })
  }

  public trackItem(index: number, item: any) {
    return item.trackId;
  }

  insertSpaces(text: string) {
    text = text.replace(/([a-z])([A-Z])/g, '$1 $2');
    text = text.replace(/([A-Z])([A-Z][a-z])/g, '$1 $2')
    return text;
  }

  sortByDate(): number {
    return 0;
  }

  changeOrder() {
    this.historyArray = this.historyArray.reverse();
  }

  openScanLogs() {
    const dialogRefNotes = this.dialog.open(ScanLogsComponent, {
      width: '800px',
      height: '500px',
      data: { scanLogs: this.scanLogs },
    });

    dialogRefNotes.beforeClosed().subscribe((data) => {
      console.log('closed');
    });
  }
}
