<div class="container-fluid">
    <div class="row justify-content-between border-bottom mb-3">
        <div class="col-9 pl-3">
            <h2 mat-dialog-title>Scan Logs</h2>
        </div>

        <div class="col-3 pr-3 text-right">
            <mat-icon mat-dialog-close>clear</mat-icon>
        </div>
    </div>

    <div class="">
        <h4>{{scanLogs.title}}</h4>
        <div class="row justify-content-between">
            <div class="col">
                <div class="card radius-10 border-start border-0 border-3 border-warning">
                    <div class="card-body">
                        <div class="d-flex align-items-center">
                            <div>
                                <p class="mb-0 text-secondary">Start Date & Time</p>
                                <p class="mb-0 text-muted">{{scanLogs.start_date | date:'medium'}}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col">
                <div class="card radius-10 border-start border-0 border-3 border-warning">
                    <div class="card-body">
                        <div class="d-flex align-items-center">
                            <div>
                                <p class="mb-0 text-secondary">End Date & Time</p>
                                <p class="mb-0 text-muted">{{scanLogs.end_date | date:'medium'}}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col">
                <div class="card radius-10 border-start border-0 border-3 border-warning">
                    <div class="card-body">
                        <div class="d-flex align-items-center">
                            <div>
                                <p class="mb-0 text-secondary">Duration</p>
                                <p class="mb-0 text-muted">{{scanLogs.duration}}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="container mt-2" style="max-height: 280px;overflow: scroll;">
        <div class="row">
            <div class="col-lg-8 col-md-10 col-12">
                <div *ngFor="let date of dateArray">
                    <h5 class="mt-4"> <span class="p-2 bg-light shadow rounded text-dark">{{date}}</span>
                    </h5>
                    <ul class="mt-3">
                        <li *ngFor="let scanLog of scanLogs.data[date]" class="ml-3"
                            [ngClass]="{'text-danger': scanLog.is_error == 1, 'text-muted' : scanLog.is_error == 0}"><i
                                class="mdi mdi-circle-medium mr-2"></i>({{scanLog.created_at | date :'fullTime'}})
                            {{scanLog.logs}}</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>

    <mat-dialog-actions align="end">

        <div *ngIf="data.detailmode === true" class="w-100 px-2">
            <button mat-flat-button mat-dialog-close class="btn-main"> Close</button>
        </div>

    </mat-dialog-actions>


</div>