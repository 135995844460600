import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface DialogData {
  scanLogs: any;
  detailmode: boolean;
}
@Component({
  selector: 'app-scan-logs',
  templateUrl: './scan-logs.component.html',
  styleUrls: ['./scan-logs.component.scss']
})
export class ScanLogsComponent implements OnInit {
  scanLogs: any;
  dateArray: string[] = [];
  constructor(public dialogRef: MatDialogRef<ScanLogsComponent>, @Inject(MAT_DIALOG_DATA) public data: DialogData,) {
    this.scanLogs = this.data.scanLogs || '';
    this.dateArray = Object.keys(this.data.scanLogs.data);
    console.log(this.dateArray);
  }

  sendscanLogs() {
    // this.scanLogs === undefined || this.scanLogs === null || this.scanLogs === '' || this.scanLogs === '' ? '' : this.scanLogs;
    this.scanLogs = this.scanLogs || '';
    this.doaction();
  }

  doaction() {
    this.dialogRef.close(this.scanLogs);
  }

  ngOnInit(): void {
  }

}
