import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

export interface Client {
  id: number | 'all';
  client_name: string;
  address: any;
  state: any;
  country: any;
  value: number;
  viewValue: string;
}
export interface GlobalFilter {
  isShow: boolean;
}

export interface Warehouse {
  id: number;
  name: string;
  value: number;
  viewValue: string;
  address: string;
  city: string;
  state: string;
  country: string;
  zip_code: string;
  region: any;
  phone: string;
}
export interface Guide {
 step:number
}

@Injectable({ providedIn: 'root' })
export class CommonService {
  private subjectName = new Subject<any>();
  private clients$ = new BehaviorSubject<Client[] | null>(null);
  private warehouses$ = new BehaviorSubject<Warehouse[] | null>(null);
  private selectedClient$ = new Subject<Client | null>();
  private selectedWarehouse$ = new Subject<Warehouse | null>();
  private startQuide$ = new Subject<Guide | null>();
  private GlobalFilter$ = new Subject<GlobalFilter | null>();


  

  sendUpdate(message: string) {
    this.subjectName.next({ text: message });
    console.log('c', message);
  }

  getUpdate(): Observable<any> {
    return this.subjectName.asObservable();
  }

  setClients(clients: Client[]) {
    this.clients$.next(clients);
  }
  setGlobalFilters(isShow: GlobalFilter) {    
    this.GlobalFilter$.next(isShow);
  }
  getGlobalFilters() {
    return this.GlobalFilter$.asObservable();
  }
  

  getClients() {
    return this.clients$;
  }

  setWarehouses(warehouses: Warehouse[]) {
    this.warehouses$.next(warehouses);
  }

  getWarehouses() {
    return this.warehouses$;
  }

  setSelectedClient(client: Client) {
    this.selectedClient$.next(client);
  }

  getSelectedClient() {
    return this.selectedClient$.asObservable();
  }

  setSelectedWarehouse(warehouse: Warehouse) {
    
    
    this.selectedWarehouse$.next(warehouse);
  }

  getSelectedWarehouse() {
    return this.selectedWarehouse$.asObservable();
  }
  setStartQuide(guide: Guide) {
    this.startQuide$.next(guide);
  }

  getStartGuide() {
    return this.startQuide$.asObservable();
  }
  downloadCSVFile(data: string, fileName: string) {
    const blob = new Blob([data], { type: 'text/csv' });
    const link = document.createElement('a');
    const date = new Date();
    link.download = `${fileName}_${date.toLocaleDateString()}.csv`;
    link.href = window.URL.createObjectURL(blob);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
}
