import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HistoryService {
  private snackbarSubject = new Subject<{ show: boolean, audits: any }>();
  public historyState = this.snackbarSubject.asObservable();

  constructor() { }

  show(auditData: any) {
    this.snackbarSubject.next({
      show: true,
      audits: auditData,
    });
  }

  close() {
    this.snackbarSubject.next({
      show: false,
      audits: [],
    });
  }
}
